import { useState, useEffect, useCallback } from "react";

import { useAtom } from "jotai";
import CounterContainer from "./charts/counter/CounterContainer";
import History from "./charts/history/history";
import Groups from "./charts/groups/groups";

// import {getFormattedDate, getDateStringMonthsAgo, getDateMonthsAgo} from "./utils"
import { FilterTeams, activeTeamsAtom, weekIndexAtom, weeksAtom, WeekSelector, SeniorityFilter, LocationFilter } from "./filters/main-filters";
// import { locationsAtom, senioritiesAtom } from "./charts/groups/groups";

import './main-dashboard.css'

import categories from "./categories.json"

import useFetch from "../../../utils/Clerk";
import EmptyDashboardAnimation from "components/ui/empty-dashboard";
const back_url = process.env.REACT_APP_API_URL;

export function MainDashboard({activeOrganization}) {
  const fetch = useFetch();
  const [loading, setLoading] = useState(true)

  // set list of teams from API /dashboard/filter, including None when users are not asigned to any team
  const [activeTeams] = useAtom(activeTeamsAtom)

  // set list of locations from API /dashboard/filter
  const [activeLocations, setActiveLocations] = useState([])
  const [locations, setLocations] = useState([])
  console.log(locations)

  // set list of seniorities from API /dashboard/filter
  const [activeSeniorities, setActiveSeniorities] = useState([])
  const [seniorities, setSeniorities] = useState([])
  console.log(seniorities)

  // Week filter
  const [weeks, setWeeks] = useAtom(weeksAtom)
  const [currentWeekIndex] = useAtom(weekIndexAtom)
  const [weekData, setWeekData] = useState([])

  // Page data used by all components
  const [filteredData, setFilteredData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  const filterData = useCallback((selectedTeams, selectedLocations, selectedSeniorities) => {
    return initialData.filter((item) => {
      // Check if item has at least one team in the selected teams
      const teamMatch = selectedTeams.length === 0 || item.team_ids.some(team => selectedTeams.includes(team));
      
      // Check if item's location is in the selected locations
      const locationMatch = selectedLocations.length === 0 || selectedLocations.includes(item.timezone);
      
      // Check if item's seniority is in the selected seniorities range
      const seniorityMatch = selectedSeniorities.length === 0 ||
      (item.seniority >= selectedSeniorities[0] && item.seniority <= selectedSeniorities[1]);

      // Return true if all criteria match
      return teamMatch && locationMatch && seniorityMatch;
    });
  }, [initialData]);

  
  useEffect(() => {
    fetch(`${back_url}/v1/organizations/${activeOrganization}/dashboard/assessment`)
      .then(res => {
        setInitialData(res.data)
        setFilteredData(res.data)
        setLoading(false)
      })
  },[activeOrganization, fetch])

  // get the list of weeks, locations, seniorities to establish filter values
  useEffect(() => {
    setWeeks([...new Set(initialData.sort((a, b) => b.id - a.id).map(item => item.week))])
  },[initialData, setWeeks])

  useEffect(() => {
    setLocations([...new Set(initialData.map(item => item.timezone))])
  },[initialData, setLocations])

  useEffect(() => {
    setSeniorities([...new Set(initialData.map(item => item.seniority))])
  },[initialData, setSeniorities])

  // when the data or a filter changes, recompute
  useEffect(() => {
    setFilteredData(filterData(activeTeams, activeLocations, activeSeniorities))
  }, [filterData, activeTeams, activeLocations, activeSeniorities])
  
  useEffect(() => {
    setWeekData(filteredData.filter((employee) => employee.week === weeks[currentWeekIndex]))
  }, [filteredData, weeks, currentWeekIndex])

  return (
    <>
    {loading ?
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
    :
    <>
      <div style={{display:'flex', justifyContent: 'space-between'}}>
        <div className="filter-wrapper">
          <FilterTeams activeOrganization={activeOrganization} />
          <LocationFilter
          locations={locations}
          activeLocations={activeLocations}
          setActiveLocations={setActiveLocations}
          />
          <SeniorityFilter
          seniorities={seniorities}
          setActiveSeniorities={setActiveSeniorities}
          />
        </div>
        <WeekSelector />
      </div>
    {weekData.length === 0 ?
    <DashboardEmptyState />
    :
      <div className="isolation-grid-container">
        {categories.map((cat, index) => (
            <div key={index} className='item'>
              <CounterContainer
                employeeesWeekData={weekData} 
                category={cat}
              />
            </div>
        ))}
        <div className={`wide-1`}>
          <History
            data = {filteredData}
          />
        </div>
        <div className={`wide-2`}>
          <Groups
            data = {filteredData}
            activeOrganization = {activeOrganization}
          />
        </div>
      </div>
}
    </>
    }
    </>
  );
}

const DashboardEmptyState = () => {
  return (
    <div className="dashboard-empty-state">
      <EmptyDashboardAnimation />
      <div id="dashboard-label">Sorry. No data corresponds to these filters.</div>
    </div>
  );
};
