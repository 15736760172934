import './user-card.css';
// import { FaSlack } from "react-icons/fa";
import ArchiveModal from '../archive-modal/archive-modal';
import { SelectTeamsModal } from "../select-teams-modal/select-teams-modal";
import { ArchiveProvider } from '../../../contexts/users-context';
import { useUsers, UserTeamsProvider } from '../../../contexts/users-context';


export function UserCard({ user, activeOrganization }) {
    // const [surveys, setSurverys] = useState(user.is_surveyed);
    // const fetch = useFetch();

    // function handleSurveyChange(surveyCheckEvent) {
    //     setSurverys(surveyCheckEvent.target.checked);
    //     fetch(`${back_url}/v1/organizations/${activeOrganization}/users/${user.id}`, {
    //         method: 'PUT',
    //         body: JSON.stringify([{ key: 'is_surveyed', value: surveyCheckEvent.target.checked }])
    //     })
    //         .then(data => {
    //             console.log("Success!")
    //         })
    //         .catch(error => console.error('Error updating user survey status:', error))   
    // }

    console.log(`Seniority: ${user.name} / ${user.first_day}`)

    return (
        <div className="users-card">
            <div className="flex-container">
                <div>
                    <div className="employee-name"> {user.full_name}</div>
                    <div className='item-container'>
                        <span className="employee-email">{user.email}</span>
                        <ArchiveProvider>
                        <ArchiveModal
                        userId={user.id}
                        isArchived={user.is_archived}
                        activeOrganization={activeOrganization}
                        />
                        </ArchiveProvider>
                    </div>
                    
                </div>
                <img alt='' src={user.image_url} className='profile-image' />
            </div>
            <hr className="divider" />
            <div className="flex-container">
                <p>Teams</p>
                <div className="item-container">
                    <UserTeamsProvider>
                        <UserTeams user={user} activeOrganization={activeOrganization}/>
                    </UserTeamsProvider>
                </div>
            </div>
            <div className="flex-container">
                <p>Location</p>
                <div style={{fontWeight:300, fontSize:12}}>
                    {user.timezone}
                </div>
            </div>
            <div className="flex-container">
                <p>Seniority</p>
                <div style={{fontWeight:300, fontSize:12}}>
                    {getTimeDifferenceYears(user.first_day)}
                </div>
            </div>
            {/* <div className="flex-container">
                <p>Connected Tools</p>
                <div className="item-container">
                    <FaSlack />
                </div>
            </div>
            <div className="flex-container">
                <p>Analytics</p>
                <div>
                    <input type="checkbox" hidden id={`analytics${user.id}`} />
                    <label className="switch" htmlFor={`analytics${user.id}`}></label>
                </div>
            </div> */}
            {/* <div className="flex-container">
                <p>Surveys</p>
                <div>
                    <input
                    type="checkbox"
                    checked={surveys}
                    onChange={handleSurveyChange}
                    hidden
                    id={`surveys${user.id}`}
                    />
                    <label className="switch" htmlFor={`surveys${user.id}`}></label>
                </div>
            </div> */}
        </div>
    )
}

function UserTeams({user, activeOrganization}) {
    const {teams} = useUsers();

    return (
        <>
        {/* Render user teams */}
        { user.team_ids.map((userTeam) => (
            <>
            {teams.find((team) => team.id === userTeam)?.name &&
            <div className="teams-label" style={{ background: '#FFC329' }}>{teams.find((team) => team.id === userTeam)?.name}</div>
            }
            </>
        ))
        }
        <SelectTeamsModal
            userId={user.id}
            activeOrganization={activeOrganization}
        />
    </>
    )
}

function getTimeDifferenceYears(timestamp) {

    if (timestamp === null) {
        return "-"
    } else {
        // Get the current timestamp
        const now = new Date();

        // Convert the given timestamp to a Date object
        const givenDate = new Date(timestamp);

        // Calculate the difference in milliseconds
        const differenceInMs = now - givenDate;

        // Calculate the difference in years
        const differenceInYears = differenceInMs / (1000 * 60 * 60 * 24 * 365.25); // accounting for leap years

        // Check if the difference is less than a year
        if (differenceInYears < 1) {
            return "less than a year";
        } else {
            // Calculate the completed number of years
            const completedYears = Math.floor(differenceInYears);
            return `${completedYears}+ year${completedYears > 1 ? 's' : ''}`;
        }
    }
}