import React, { useEffect, useState } from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import { Button } from "components/ui/button"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
  } from "components/ui/popover";
import { cn } from 'lib/utils';

const SliderDemo = React.forwardRef(({ className, min = 0, max, step, formatLabel, value, onValueChange, ...props }, ref) => {
    const [localValues, setLocalValues] = useState([]);

    const handleValueChange = (newValues) => {
        setLocalValues(newValues);
        if (onValueChange) {
            onValueChange(newValues);
        }
    };
    useEffect(() => {
        setLocalValues([min, max])
    }, [min, max])
    return (
        <Popover>
            <PopoverTrigger>
                <Button variant="outline">Seniority</Button>
            </PopoverTrigger>
            <PopoverContent>
                <div className="grid gap-4 p-4 w-full max-w-80 bg-white border border-[#14424C]/20 rounded-[12px]">
                    <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Seniority
                    </label>
                    <SliderPrimitive.Root
                        ref={ref}
                        min={min}
                        max={max}
                        step={step}
                        value={localValues}
                        onValueChange={handleValueChange}
                        className={cn('w-full relative flex w-full touch-none select-none items-center', className)}
                        {...props}
                    >
                        <SliderPrimitive.Track className="relative h-1.5 w-full grow overflow-hidden rounded-full bg-primary/20">
                            <SliderPrimitive.Range className="absolute h-full bg-primary" />
                        </SliderPrimitive.Track>
                        <SliderPrimitive.Thumb className="block h-4 w-4 rounded-full border border-primary/50 bg-background shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50" />
                        <SliderPrimitive.Thumb className="block h-4 w-4 rounded-full border border-primary/50 bg-background shadow transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50" />
                    </SliderPrimitive.Root>
                    <div className="flex justify-between mt-2 text-xs">
                        <span>{formatLabel ? formatLabel(localValues[0]) : localValues[0]}</span>
                        <span>{formatLabel ? formatLabel(localValues[1]) : localValues[1]}</span>
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    );
});

SliderDemo.displayName = SliderPrimitive.Root.displayName;

export default SliderDemo;
