import React from "react";

const EmptyDashboardAnimation = () => {
  //
  return (
    <div style={{
      width: '410px',
      height: '100%',
      margin: '0',
      backgroundColor: '#F2F6F7'
    }}>
      <svg
        id="dashboard-empty-state"
        xmlns="http://www.w3.org/2000/svg"
        width="400"
        height="288"
        viewBox="0 0 110 72"
        style={{ opacity: 0.8 }}
      >
        <style>
          {`
            .red {
              animation: rocking-red 2.4s 0.667s forwards;
            }

            .purple {
              transform: translateY(-35px);
              animation: bounce-purple 1s 0.42s forwards;
            }

            .grey {
              stroke-dashoffset: -40.5px;
              animation: trace-stroke 1.6s 0.46s forwards;
            }

            @keyframes rocking-red {
              0% {
                transform-origin: 74px 36px;
                transform: rotateZ(0deg) translateY(0px);
                animation-timing-function: cubic-bezier(.62,.01,.55,.99);
              }
              33% {
                transform-origin: 85.1px 36px;
                transform: rotateZ(58deg) translateY(1.1px);
                animation-timing-function: cubic-bezier(.54,-0.08,.51,1);
              }
              55.6% {
                transform-origin: 83px 36px;
                transform: rotateZ(30deg) translateY(2.4px);
                animation-timing-function: cubic-bezier(.52,-0.08,.7,.99);
              }
              75% {
                transform-origin: 83.5px 36px;
                transform: rotateZ(52deg) translateY(0.7px);
                animation-timing-function: cubic-bezier(.53,-0.02,.68,.91);
              }
              90% {
                transform-origin: 81.5px 36px;
                transform: rotateZ(41.8deg) translateY(0.5px);
                animation-timing-function: cubic-bezier(.52,.06,.65,.99);
              }
              100% {
                transform-origin: 81.5px 36px;
                transform: rotateZ(45deg) translateY(0px);
              }
            }

            @keyframes bounce-purple {
              0% {
                transform: translateY(-35px);
                animation-timing-function: cubic-bezier(.85,.01,.83,.92);
              }
              70% {
                transform: translateY(0px);
                animation-timing-function: cubic-bezier(.23,.27,.79,.91);
              }
              85% {
                transform: translateY(-2px);
                animation-timing-function: cubic-bezier(.32,.2,.75,.99);
              }
              100% {
                transform: translateY(0px);
              }
            }

            @keyframes trace-stroke {
              0% {
                stroke-dashoffset: -40.5px;
                animation-timing-function: cubic-bezier(.7,-0.02,.72,.8);
              }
              24% {
                stroke-dashoffset: -31.5px;
                animation-timing-function: cubic-bezier(.62,.08,.76,.87);
              }
              56% {
                stroke-dashoffset: -8.2px;
                animation-timing-function: cubic-bezier(.16,.24,.34,1);
              }
              100% {
                stroke-dashoffset: 0px;
              }
            }
          `}
        </style>

        <path
          className="red"
          d="M74,18H92A18,18,0,0,1,74,36Z"
          style={{ fill: "#ff6161", fillRule: "evenodd" }}
        />
        <circle
          className="purple"
          cx="64.96"
          cy="45"
          r="9"
          style={{ fill: "#716bf1" }}
        />
        <path
          className="grey"
          d="M6.64,49.5l8.86,0a42.86,42.86,0,0,1,9-21l0-9.35"
          style={{
            fill: "none",
            stroke: "#81a2b2",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: "9px",
            strokeDasharray: "0.1 500",
          }}
        />
        <path
          className="blue"
          d="M56,54V18A36,36,0,0,0,20,54Z"
          style={{ fill: "#33afff", fillRule: "evenodd" }}
        />
        <rect
          className="yellow"
          x="73.96"
          y="36"
          width="18"
          height="18"
          style={{ fill: "#ffce53" }}
        />
      </svg>
    </div>
  );
};

export default EmptyDashboardAnimation;
